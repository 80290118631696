import {DefaultPrivacyLevel} from "@datadog/browser-rum";

enum Site {
  US1 = 'datadoghq.com'
}

type ConsoleApiName = 'log' | 'debug' | 'info' | 'warn' | 'error';

export const environment = {
  version: '1.1.0', // This is the version of the application
  production: true,
  resourceServerURL: 'http://localhost:8081',
  apiServer: 'https://launcher.fullswingapps.com/api/fs',
  awsAPIURL: 'https://l9otz28laj.execute-api.us-west-2.amazonaws.com/prod',
  hostName: 'baymanageradmin.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'dashboard',
  //Local storage values
  navName: '_fs_nav_name',

  datadog: {
    enableDatadogLogs: true,
    enableDatadogRum: true,
    rumConfig: {
      applicationId: '035e022c-87a6-41ba-b8e5-af6d31fde602',
      clientToken: 'pubf13fb6ce0f08ea56d75f8c2b1df2f4ee',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: Site.US1,
      service: 'admin-utility-website',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    },
    // make the forwardConsoleLogs only to "warn" using the ConsoleApiName[] required


    logsConfig: {
      clientToken: 'pubf13fb6ce0f08ea56d75f8c2b1df2f4ee',
      site: Site.US1,
      service: 'admin-utility-website',
      env: 'prod',
      version: '1.1.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: [ 'warn', 'error'] as ConsoleApiName[],
      sessionSampleRate: 100,
    }
  }
};
